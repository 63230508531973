import { Validators } from "@angular/forms";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { HttpStatusCodeEnum } from "@app/models/http-status-code.enum";

export const CONSTANTS = {
  DEFAULT_DATE_FORMAT: "DD.MM.YYYY",
  ADAPTER_DATE_FORMAT_DE: {
    parse: {
      dateInput: "DD.MM.YYYY",
    },
    display: {
      dateInput: "DD.MM.YYYY",
      monthYearLabel: "MMMM YYYY",
      dateA11yLabel: "LL",
      monthYearA11yLabel: "MMMM YYYY",
    },
  },
  FLAT_FINDER: {
    COLORPICKER_MSG: "Geben Sie auch eigene Farbcodes manuell ein (RGB, HEX, HSW oder sonstiges)",
  },
  TOKEN_REFRESH_TIMER: 3000000,
  ESOFT: {
    LOGO_SEPARATE_PRODUCT_PACKAGE_NUMS: [
      "2", // Bildbearbeitung - Drohnenaufnahmen - Foto
      "23", // Bildbearbeitung - Innenfotos
      "6", // Bildbearbeitung - Fotonachbearbeitung
    ],
  },
  CONFIGURATIONS: {
    SERVICE_PROVIDERS: "assignServiceProvider",
    DOC_ESTATE: "docestate",
    ESOFT: "esoft",
    FAIRFLEET: "fairfleet",
    OFFENBLENDE: "offenblende",
    BACKBONE: "backbone",
    GRUNDRISS_SCHMIEDE: "grundrissSchmiede",
    AUTO_DETECT_SERVICES: [],
    MC_GRUNDRISS: "mcgrundriss",
    IMMOVIEWER: "immoviewer",
    MC_GRUNDRISS_PACKAGES: [
      // TODO: This configuration could be stored in our database, instead of being here hardcoded. It was added here just to solve the issue faster.
      "4", // Klassisch
      "5", // Extravagant ("3D")
      "24", // 2D Premium
      "26", // Sparkasse
      "40", // LBS
      "43", // FALC
      // "44", // BPD
      "46", // RE/MAX
      "48", // FALC Variante 2
      "52", // Dahler & Company
      //"53", // Pink 2D Premium
      "56", // SIS
      "57", // PlanetHome
      "62", // PROFIMEO
      "63", // Schmidt & Schurig
    ],
    MC_GRUNDRISS_USER_ID_AT_IMOGENT: "7gcOTwJcuShO1W6D64geaA7Tcrx2", // TODO: This is just a temporary fix!
    EXCLUDED_MC_GRUNDRISS_PACKAGES: [
      "5", // Extravagant ("3D")
      "24", // 2D Premium
      "53", // Pink 2D Premium
    ],
    POST_PROCESSING_PACKAGES_LIST: [
      "1", // dronemedia video
      "2", // dronemedia photos
      "12", // hdphotos editorial
      "23", // hdphotos realestate
      "31", // video_tour objectbegehung
      "33", // video_tour imagefilm
      "37", // virtual_tour immoviewer
    ],
    AUTO_PILOT: "autoPilot",
    ORDER_TYPE_RESPONSIBLES: "orderTypeResponsibles",
    PACKAGES_DELIVERY_TIMES: "packagesDeliveryTimes",
    LANDING_PAGE_TRACKING_LIST: "landingPageTrackingList",
    REPORTING_PRESETS: "reportingPresets",
    JLL_COMPANY_IDS: ["OZthUnBReNW1F9Yqvai0", "M5IYpTepB9g0b9P74poO"],
    ONLINE_ORDER_TYPES: ["price_hubble", "virtual_tour"], // Order types that are stored or hosted online
    MOLLIE: {
      PROFILE_ID: "pfl_GxgdcMGDkQ",
      LOCALE: "de_DE",
    },
  },
  BACKEND_ENDPOINTS: {
    PRODUCT_BUNDLES: "product-bundles",
    MANAGMENT_BOARD: "management-board",
    ACCOUNTINGPOSITIONS: "accounting-positions",
    COMPANIES: "companies",
    CONFIGURATION: "configuration",
    DISCOUNTS: "discounts",
    EVENT_SUBSCRIPTIONS: "event-subscriptions",
    FILES: "files",
    INVOICES: "invoices",
    ORDERS: "orders",
    REAL_ESTATES: "real-estates",
    SERVICES: "services",
    SUBCOLLECTIONS: "subcollections",
    SUBORDERS: "suborders",
    USERS: "users",
    VIEWS: "views",
    SENDEMAIL: "send-email",
    MCGRUNDRISS: "mcgrundriss",
    PAYMENTS: "payments",
    APIS: "apis",
    WEBHOOKS: "webhooks",
    EMAIL_TEMPLATES: "email-templates",
    DOCESTATE: "documents-procurement",
    DOCESTATE_CI: "docestate-ci",
    MOOVIN_CI: "moovin-ci",
    ANALYTICS: "analytics",
    GRUNDRISS_SCHMIEDE: "area-calculation",
    RATING: "rating",
    IMMOVIEWER: "immoviewer",
    FEEDBACKS: "feedbacks",
    ON_OFFICE: "onoffice",
    NEWS_SLIDES: "news-slides",
    FACT_SHEETS: "fact-sheets",
  },
  COLLECTIONS: {
    PRODUCT_BUNDLES: "product_bundles",
    ACCOUNTING_POSITIONS: "accounting_position",
    COMPANIES: "companies",
    CONFIGURATIONS: "configuration",
    DISCOUNTS: "discounts",
    INVOICES: "invoices",
    ORDERS: "orders",
    REAL_ESTATES: "realestates",
    SERVICES: "services",
    SUBORDERS: "suborders",
    USERS: "users",
    VIEWS: "views",
  },
  EXTERNAL_APIs: {
    ON_OFFICE: "onoffice",
    FAIRFLEET: "fairfleet",
    TRANSLATE: "translate",
    IMMOVIEWER: "immoviewer",
    LEXOFFICE: "lexoffice",
    OFFENBLENDE: "offenblende",
    MOOVIN: "moovin",
    RENEWA: "renewa",
  },
  THUMBNAIL_SIZES_SUFFIXES: ["thumbXS", "thumbM", "thumbXL"],
  EVENTS: {
    MARKER_TOOL: {
      ADDED_COUNTER_MARKER: "markerToolAddedCounterMarker",
      REMOVED_COUNTER_MARKER: "markerToolRemovedCounterMarker",
    },
    STORAGE: "storage",
  },
  SUPPORTED_IMAGE_EXTENSIONS: [".gif", "jpg", "jpeg", "png", ".svg"],
  SUPPORTED_LOGOS_EXTENSIONS: ["png"],
  WEBSOCKET_EVENTS: {
    CONNECTION: "connection",
    DISCONNECT: "disconnect",
    ORDERS: {
      SUBSCRIBE: "orders.subscribeOrders",
      UNSUBSCRIBE: "orders.unsubscribeOrders",
      CHANGED: "orders.changed",
    },
    SUBORDERS: {
      SUBSCRIBE_SUBORDERS_OF_ORDER: "subscribeToSubordersOfOrder",
      SUBSCRIBE_SUBORDER_LIST: "subscribeToSuborderList",
      SUBSCRIBE_SUBCOLLECTION: "subscribeToSubCollection",
      UNSUBSCRIBE_SUBORDERS_OF_ORDER: "unsubscribeToSubordersOfOrder",
      UNSUBSCRIBE_SUBORDER_LIST: "unsubscribeToSuborderList",
      UNSUBSCRIBE_SUBCOLLECTION: "unsubscribeToSubCollection",
      SUBORDER_CHANGES: "suborderChanges",
      SUBORDER_LIST_CHANGES: "mySuborderListChanges",
      SUBCOLLECTION_CHANGES: "subCollectionChanges",
      UNSUBSCRIBE_ALL: "unsubscribeAll",
      SUBSCRIBE_SUBORDER: "subscribeToSuborder",
      CURRENT_SUBORDER_CHANGES: "currentSuborderChanges",
      UNSUBSCRIBE_SUBORDER: "unsubscribeToSuborder",
      SUBSCRIBE_REALESTATE_SUBORDERS: "subscribeToRealEstateSuborders",
      REALESTATE_SUBORDER_CHANGES: "realEstateSuborderChanges",
      UNSUBSCRIBE_REALESTATE_SUBORDERS: "unsubscribeToRealEstateSuborders",
      SUBSCRIBE_ALL_SUBORDERS: "subscribeToAllSuborders",
      ALL_SUBORDER_CHANGES: "allSuborderChanges",
      MY_ASSIGNED_SUBORDER_CHANGES: "myAssignedSuborderChanges",
      UNSUBSCRIBE_ALL_SUBORDERS: "unsubscribeToAllSuborders",
      SUBSCRIBE_MY_ASSIGNED_SUBORDERS: "subscribeToMyAssignedSuborders",
      UNSUBSCRIBE_MY_ASSIGNED_SUBORDERS: "unsubscribeToMyAssignedSuborders",
      SUBSCRIBE_ALL_PAYMENTS_SUBORDERS: "subscribeToAllPaymentsSuborders",
      UNSUBSCRIBE_ALL_PAYMENTS_SUBORDERS: "unsubscribeToAllPaymentsSuborders",
      ALL_PAYMENTS_SUBORDER_CHANGES: "allPaymentsSuborderChanges",
    },
  },
  SPARKASSEN_DOMAINS: ["spk-immo.imogent.com"],
  CUR_YEAR: new Date().getFullYear(),
  REAL_ESTATE_SAVE_FOR_LATER: {
    TITLE: "Wird später festgelegt",
  },
  REAL_ESTATES_DEFAULT_PAGE_SIZE: 20,
  REG_EXP: {
    ISO_DATE: /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/,
    BPD_COMPANY_NAME: /^bpd\s/i, // All BPD companies start with this keyword.
  },
  EMAILREGEX: new RegExp("^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]{2,9})*$"),
  VALIDATION_PATTERN: {
    STREET: Validators.pattern("^([A-ZäöüÄÖÜß.-][a-zA-ZäöüÄÖÜß. -]*[a-zA-ZäöüÄÖÜß.]$)"),
    STREET_NUMBER: Validators.pattern("[1-9][0-9a-zA-Z -]{0,10}$"),
    POSTALCODE: Validators.pattern("^[0-9]{4,5}$"),
    CITY: Validators.pattern("^([A-ZäöüÄÖÜß -][a-zA-ZäöüÄÖÜß -]*[a-zA-ZäöüÄÖÜß]$)"),
    TAXID: Validators.pattern("(DE)?[0-9]{9}"),
    TAXID_GER: Validators.pattern("(DE)?[0-9]{9}"),
  },
  VALIDATION_ERROR_MSG: {
    TAXID_INVALID: "Bitte geben Sie eine gültige Ust-Id im Format DE123456789 an.",
    EMAIL_INVALID: "E-Mail-Adresse ist ungültig.",
  },
  PACKAGE_KEYS: {
    // ARCHILOGIC: "42", Deprecated
    DRONEMEDIA_FOTOS: "2",
    RETOUCHING_EXTENDED: "25",
    HD_PHOTOS_REALESTATE: "23",
    HD_PHOTOS_BACKBONE: "55",
    HD_PHOTOS_EDITORIAL: "12",
    VIRTUAL_TOUR_IMMOVIEWER: "37",
    VIRTUAL_TOUR_MATTERPORT: "13",
    PRICE_HUBBLE_DOSSIER: "47",
    DRONEMEDIA_VIDEO: "1",
    RUNDGANG_360: "39",
    VIDEO_TOUR_OBJEKTBEGEHUNG: "31",
    VIDEO_TOUR_IMAGEFILM: "33",
    VIDEO_TOUR_DIY: "38",
    FLOORPLAN_BASED_AREA_CALC: "60",
    ON_SITE_AREA_CALC: "61",
    PLANET_HOME_FLOORPLAN: "57",
    DOCUMENT_PROCUREMENT: "28",
    FLOORPLAN_KLASSICH: "4",
    FLOORPLAN_PREMIUM: "24",
    FLOORPLAN_EXTRAVAGANT: "5",
    FLOORPLAN_SPARKASSE: "26",
    FLOORPLAN_LBS: "40",
    FLOORPLAN_FALC: "43",
    FLOORPLAN_FALC2: "48", //Needs to be review.
    FLOORPLAN_BPD: "44",
    FLOORPLAN_HELMA1: "20",
    FLOORPLAN_HELMA2: "35",
    FLOORPLAN_REMAX: "46",
    FLOORPLAN_DAHLER_COMPANY: "52",
    FLOORPLAN_PINK_2D_PREMIUM: "53",
    FLOORPLAN_SIS_2D: "56",
    FLOORPLAN_PLANETHOME_2D: "57",
    FLOORPLAN_PROFIMEO: "62",
    VSTAGING_STILL_IMAGE: "8",
    VSTAGING_360_IMAGE: "9",
    VSTAGING_OFFICE_360_IMAGE: "41",
    VSTAGING_OFFICE_STANDBILD: "50",
    VSTAGING_OFFICE_360_BILDER: "51",
    RETOUCHING_BASIC: "6",
    RETOUCHING_STANDARD: "7",
    RETOUCHING_PREMIUM: "25",
    VISUALIZATION_INNENVISUALISIERUNG: "10",
    VISUALIZATION_AUSSENVISUALISIERUNG: "11",
    VISUALIZATION_360: "39",
    ENERGYPASS_DEMAND_CERTIFICATE: "58",
    ENERGYPASS_CONSUMPTION_CERTIFICATE: "59",
    FLOORPLAN_SCHMIDT_SCHURIG: "63",
    FLOORPLAN_CLASSIC: "64",
    ENERGY_ASSESSMENT: "65",
  },
  MANDATORY_FILE_CHECKLIST: [
    "4",
    "5",
    "24",
    "26",
    "40",
    "43",
    "48",
    "44",
    "20",
    "35",
    "46",
    "52",
    "53",
    "56",
    "57",
    "8",
    "9",
    "41",
    "50",
    "51",
    "6",
    "7",
    "25",
    "60",
  ],
  VSTAGING_360_PACKAGE_NUM_LIST: ["9", "51"],
  INVOICE_POSITION_ORDER_QUANTITY_MAP: {
    floorplan_price_klassisch_wohnen: "additionalFloor",
    floorplan_price_klassisch_gewerbe: "additionalFloor",
    floorplan_price_premium_wohnen: "additionalFloor",
    floorplan_price_premium_gewerbe: "additionalFloor",
    floorplan_price_extravagant_wohnen: "additionalFloor",
    floorplan_price_extravagant_gewerbe: "additionalFloor",
    floorplan_price_sparkasse_wohnen: "additionalFloor",
    floorplan_price_sparkasse_gewerbe: "additionalFloor",
    floorplan_price_lbs_gewerbe: "additionalFloor",
    floorplan_price_falc_wohnen: "additionalFloor",
    floorplan_price_falc_gewerbe: "additionalFloor",
    floorplan_price_remax_wohnen: "additionalFloor",
    floorplan_price_remax_gewerbe: "additionalFloor",
    floorplan_price_bpd_wohnen: "additionalFloor",
    floorplan_price_bpd_gewerbe: "additionalFloor",
    "floorplan_price_dahler-company_wohnen": "additionalFloor",
    "floorplan_price_dahler-company_gewerbe": "additionalFloor",
    floorplan_price_sis_wohnen: "additionalFloor",
    floorplan_price_sis_gewerbe: "additionalFloor",
    floorplan_price_planethome_wohnen: "additionalFloor",
    floorplan_price_planethome_gewerbe: "additionalFloor",
    retouching_price: "numberOfPhotos",
    retouching_price_basic: "numberOfPhotos",
    retouching_price_erweitert: "numberOfPhotos",
  },
  HTTP_STATUS: {
    OK: HttpStatusCodeEnum.OK,
    NO_CONTENT: HttpStatusCodeEnum.NO_CONTENT,
    ERROR: HttpStatusCodeEnum.BAD_REQUEST,
    UNAUTHORIZED: HttpStatusCodeEnum.UNAUTHORIZED,
    FORBIDDEN: HttpStatusCodeEnum.FORBIDDEN,
    NOT_FOUND: HttpStatusCodeEnum.NOT_FOUND,
    CONFLICT: HttpStatusCodeEnum.CONFLICT,
    SERVER_ERROR: HttpStatusCodeEnum.INTERNAL_SERVER_ERROR,
  },
  CURRENCIES: {
    EURO: "EUR",
  },
  REAL_ESTATES_GENERAL_SEARCH: "generalsearchterm",
  JLL_PREFIXES: {
    BUILDING: "Gebäude - ",
    OBJECT: "Object - ",
  },
  MARKERJS: {
    LICENSE_KEY: "MJS2-P527-S993-8797",
  },
  ORDER_PRICE_DEPOSIT_VALUE: 1000, // a deposit of 50% is needed when the order is bigger than this value
  SEPA_PAYMENT_OPTION_MAX_VALUE: 840.3,
  SESSION_STORAGE_KEYS: {
    ORDER_CONFIRMATION_DETAILS: "order_confirmation_details",
    LANDING_PAGE_TRACKING_REF: "landing_page_tracking_ref",
  },
  LOCAL_STORAGE_KEYS: {
    MESSAGE: "message",
    LAST_OPENED_NEWS_MODAL: "lastOpenedNewsModal",
    USER: "user",
  },
  VAT_TAX_PERCENTAGE: 19,
  DRONE_VIDEO_PACKAGES: ["1"],
  FLOORPLAN_TYPE_SERVICES: [OrderTypeEnum.Floor_Plan, OrderTypeEnum.Area_Calculation],
  SUPPORT_EMAILS: {
    DEVELOP: "dev@imogent.com",
    PRODUCTION: "service@imogent.com",
    STAGING: "dev@imogent.com",
  },
  SALES_EMAILS: {
    DEVELOP: "dev@imogent.com",
    PRODUCTION: "kontakt@imogent.com",
    STAGING: "dev@imogent.com",
  },
  PLANET_HOME_EMAIL: "imogent@planethome.de",
  SSO_SOURCES: {
    FALC: "falcimmo",
    PLANET_HOME: "planethome",
    IMMOVIEWER: "immoviewer",
    PROPSTACK: "propstack",
  },
  DOCESTATE_ESTIMATED_DELIVERY_TIME: {
    100: {
      value: 10,
      unit: "day",
    }, // Grundbuchauszug (beglaubigt)
    101: {
      value: 10,
      unit: "day",
    }, // Grundbuchauszug (unbeglaubigt)
    102: {
      value: 4,
      unit: "week",
    }, // Bewilligungsurkunde (Abteilung II)
    103: {
      value: 4,
      unit: "week",
    }, // Bewilligungsurkunde (Alle Abteilungen)
    104: {
      value: 14,
      unit: "day",
    }, // Liegenschaftskarte (amtlich)
    105: {
      value: 6,
      unit: "week",
    }, // Teilungserklärung
    120: {
      value: 4,
      unit: "week",
    }, // Bewilligungsurkunde (Abteilung III)
    200: {
      value: 4,
      unit: "week",
    }, // Altlastenauskunft
    201: {
      value: 4,
      unit: "week",
    }, // Baulastenauskunft
    202: {
      value: 2,
      unit: "week",
    }, // Kampfmittelauskunft
    203: {
      value: 6,
      unit: "week",
    }, // Gebäudedenkmalschutz
    204: {
      value: 6,
      unit: "week",
    }, // Bodendenkmalauskunft
    300: {
      value: 6,
      unit: "week",
    }, // Erschließungsbeitragsbescheinigung
    301: {
      value: 8,
      unit: "week",
    }, // Kanalanschlussgebühren
    158: {
      value: 3,
      unit: "day",
    }, // Liegenschaftskarte (nicht amtlich)
    401: {
      value: 14,
      unit: "day",
    }, // Bestandsnachweis
    402: {
      value: 14,
      unit: "day",
    }, // Flurstücks und Eigentümernachweis
    403: {
      value: 4,
      unit: "week",
    }, // Fortführungsnachweis
    404: {
      value: 4,
      unit: "week",
    }, // Grenzbescheinigung
    405: {
      value: 2,
      unit: "week",
    }, // Bodenrichtwert (-Karte)
    500: {
      value: 14,
      unit: "day",
    }, // Grundsteuerbescheid
    501: {
      value: 4,
      unit: "week",
    }, // Einheitswertbescheid
    502: {
      value: 4,
      unit: "week",
    }, // Grundsteuermessbescheid
    503: {
      value: 4,
      unit: "week",
    }, // Steuerlicher Unbedenklichkeitsbescheid (Objekt)
    504: {
      value: 1,
      unit: "day",
    }, // Handelsregisterauszug
    600: {
      value: 4,
      unit: "week",
    }, // Flächennutzungsplan
    601: {
      value: 4,
      unit: "week",
    }, // Bebauungsplan
    602: {
      value: 4,
      unit: "week",
    }, // Städtebaulicher Vertrag
    603: {
      value: 6,
      unit: "week",
    }, // Satzungs- und Gebietsauskünfte
    604: {
      value: 6,
      unit: "week",
    }, // Auskunft Flurbereinigungs- u. Stadtumbaugebiet
    605: {
      value: 6,
      unit: "week",
    }, // Städtisches Vorkaufsrecht
    606: {
      value: 6,
      unit: "week",
    }, // Business Improvement District
    607: {
      value: 14,
      unit: "day",
    }, // Auskunft Bau- u. Lärmschutz
    608: {
      value: 14,
      unit: "day",
    }, // Auskunft Natur-, Wasser- u. Landschaftsschutz
    609: {
      value: 14,
      unit: "day",
    }, // Auskunft Hochwasserschutzgebiete
  },
  DOCESTATE_BAUAKTE_PRODUCTS: [700, 701, 702, 1052],
  PACKAGE_NAMES: {
    [OrderTypeEnum.Virtual_Tour]: {
      IMMOVIEWER_RETOUCHING: "Nachbearbeitung - Immoviewer",
    },
    [OrderTypeEnum.Documents_Procurement]: {
      AUSLAGEN: "Auslagen",
    },
    [OrderTypeEnum.Drone_Media]: {
      DRONE_VIDEO: "Drohnenaufnahmen - Videos",
      DRONE_VIDEO_RETOUCHING: "Bearbeitung - Luftaufnahmen - Videos",
    },
    [OrderTypeEnum.Hd_Photos]: {
      HD_PHOTOS_EDITORIAL: "Editorial Shooting",
      HD_PHOTOS_REALESTATE: "Immobilienfotos",
    },
  },
  PLANET_HOME_EXTERNAL: "planethome",
  PLANETHOME_EMAIL_DOMAINS: ["planethome.de", "planethome.com"],
  PLANETHOME_AREA_ON_SITE_ID: "IDHEIS",
  LEXOFFICE_MAX_DISCOUNT: 100,
  LEXOFFICE_BASE_URL: "https://app.lexoffice.de/vouchers#!/VoucherView/Invoice/",
  PRICES: {
    VIDEO_LINK: 12.9,
    OUTLOOK_PHOTOGRAPHER_NEEDED: 290,
    STANDARD_DOCUMENTS: 32,
    SPECIAL_DOCUMENTS: 64,
    MEASUREMENTS_CORRECTIONS: 15,
    ADDITIONAL_FLOORS_SURCHARGE_MIN: 135,
    ADDITIONAL_FLOORS_SURCHARGE_MAX: 149,
    ADDITIONAL_BASEMENTS_SURCHARGE_MIN: 139,
    INDIVIDUAL_2D_DESIGN: 249,
  },
  QUERY_PARAMS: {
    LANDING_PAGE_TRACKING_REF: "ref", // parameter for landing page tracking ref
    DISABLE_SIDEBAR: "ds", // parameter to disable sidebar
    DISABLE_NAVBAR: "dn", // parameter to disable navbar
    REDIRECT_PATH: "redirectPath",
    OBJECT_NUMBER: "objectNumber",
    SOURCE: "source",
  },
  VIDEO_TOUR_PACKAGES: ["31", "33", "38"],
  MAX_FILESIZES: {
    MC_GRUNDRISS: 30,
    SIZE_50MB: 52428800,
    SIZE_30MB: 31457280,
    SIZE_300MB: 314572800,
    SIZE_1000MB: 1048576000,
    SIZE_24MB: 25165824,
  },
  DOCUMENTS_WITHOUT_AUTHORITY: [104, 158],
  IMOGENT_SERVICE_PHONE_NUMBER: "+49 (0) 521 54 38 26 95",
  FIREBASE_URLS: {
    CONSOLE: "https://console.firebase.google.com/project/",
    FIRESTORE_PATH: "firestore/data",
  },
  SERVICE_PHONE: "Tel: +4952154382695",
  SERVICE_PHONE_GER: "Telefon: 0521 54382695",
  FILE_SIZE_LIMIT_5MB: 5242880,
  FILE_SIZE_LIMIT_10MB: 10485760,
  PDF_EDITOR_APP_LINK: "https://www.ilovepdf.com/de/pdf_komprimieren",
  SUBORDERS_STARTING_YEAR: 2015,
  PROPERTY_TYPES: [
    "Bauernhaus",
    "Bungalow",
    "Büro",
    "Doppelhaushälfte",
    "Eigentumswohnung",
    "Einfamilienhaus",
    "Einzelhandel",
    "Garage/Stellplatz",
    "Gastronomie",
    "Grundstück",
    "Halle, Lager, Produktion",
    "Haus",
    "Ladenlokal",
    "Mehrfamilienhaus (Etage)",
    "Praxis",
    "Reihenendhaus",
    "Reihenhaus",
    "Wohn- und Geschäftshaus",
    "Wohnhaus",
    "Wohnung",
    "Sonstiges",
  ],
  AREA_CALCULATION_FREE_ROOMS_QTY: 7,
  AREA_CALCULATION_MEASUREMENT_PACKAGE: "Aufmaßerstellung vor Ort",
  AREA_CALCULATION_FLOORPLAN_PACKAGE: "Auf Basis bemaßter Grundrisse",
  HOMEDAY_COMPANY_ID: "5qE6soB4ClMKC40fUrcP", // TODO: This is for Homeday testing. Probably we'll remove it after the testing is done. Both dev and prod have same company id
  PRODUCT_BUNDLE_ACCOUNTING_POSITION_BASE_SUFFIX: "_bundle_base_price",
  COMPANY_LOCATION: {
    COUNTRY: "DE",
    STATE: "NW",
  },
  IMOGENT_DOMAINS: ["app-dev.imogent.com", "app.imogent.com", "localhost"],
  SUBORDER_DIRECT_LINK_TEMPLATES: {
    DEFAULT: "${platformURL}/orderoverview/suborder/${subOrderId}",
    PROPSTACK:
      "https://crm.propstack.de/app/portfolio/properties/${objectNumber}/imogent?url=orderoverview/suborder/${suborderId}",
  },
  PHOTOGRAPHY_ORDER_TYPES: [
    OrderTypeEnum.Drone_Media,
    OrderTypeEnum.Hd_Photos,
    OrderTypeEnum.Virtual_Tour,
    OrderTypeEnum.Video_Tour,
  ],
  USER_CONSENT_COOKIE_NAME: "userConsent",
  DOCESTATE_TEILUNG_PRODUCT_ID: 105,
  DOCESTATE_TEILUNG_ONSITE_PRODUCT_ID: 1052,
  DOCESTATE_TEILUNG_SHORT_NAME: "teilun",
  ON_OFFICE_ACTIVATION_ROUTE: "onOffice-activation",
  ZAITSEV_SERVICE_PROVIDER_ID: "xZzyykCh4WgQkD9PDVSPVeSGUv43", // TODO: this would be better added to our configurations collection, but since this would take some time, it was chosen to add it here.
  LOG_COLLECTIONS: {
    FEEDBACK_LOGS: "feedbackLogs",
  },
  MAX_FILE_RENDER_THRESHOLD: 9,
  BANNER_TEXTS: {
    IMO_BKBN:
      "Ab dem 31. Oktober 2024 werden bei IMOGENT Visualisierungen (für Neubauprojekte) deaktiviert und somit nicht mehr bestellbar sein. Selbstverständlich bieten wir Ihnen ab diesem Zeitpunkt im neuen Backbone-System die gleichen Services an. Bestellen Sie hierfür bitte zukünftig Ihre Visualisierungen unter <a href='https://app.bkbn.com'  target='_blank' rel='noopener noreferrer'>app.bkbn.com</a> für ein reibungsloses Erlebnis.",
  },
  BANNER_LINKS: {
    IMO_BKBN: "https://app.bkbn.com",
  },
  ASSETS: {
    PHOTOGRAPHY: {
      PREPARATION_TIPS:
        "https://firebasestorage.googleapis.com/v0/b/imogent-plattform.appspot.com/o/assets%2Fdocuments%2Fphotography%2FIMOGENT%20Vorbereitungshilfen.zip?alt=media&token=5fe792f5-52f3-4982-ab88-a4c29f4e2588",
    },
  },
  HDR_PHOTO_FACTOR: 3,
};
