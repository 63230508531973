import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "@app/auth/auth.service";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { GlobalService } from "@app/_services/global.service";
import { CONSTANTS } from "@app/util/constants";

@Component({
  selector: "app-partner-advertisement-banner",
  templateUrl: "./partner-advertisement-banner.component.html",
  styleUrls: ["./partner-advertisement-banner.component.css"],
})
export class PartnerAdvertisementBannerComponent implements OnInit {
  @Input() closePartnerBannerForever: boolean = true;
  @Input() bannerText: string;
  showBanner: boolean;
  partnerContent = [
    {
      partnerId: "imo_bkbn_visu",
      bannerHeader: `Abschalten der Neubauvisualisierungsleistungen auf IMOGENT! 🚨`,
      bannerText: CONSTANTS.BANNER_TEXTS.IMO_BKBN,
      link: CONSTANTS.BANNER_LINKS.IMO_BKBN,
      pathToImage: "../../assets/images/partner_images/Imogent_by-BKBN_logo_LinkedIn_RGB-02.png",
      pathToLogo: "../../assets/images/partner_images/Imogent_by-BKBN_logo_RGB_700px.png",
      background: "linear-gradient(90deg, #004f54, #F3F3EA)",
    },
  ];
  activatedRoute: string;
  constructor(
    public auth: AuthService,
    private uls: UsersLogicService,
    private route: ActivatedRoute,
    private gls: GlobalService
  ) {}

  ngOnInit(): void {
    this.activatedRoute = this.route.routeConfig.path;
    this.removeRestrictedBanners();
    this.showBanner = !this.gls.isWhitelabelVersion;
    this.auth.partnerContentChange.subscribe(() => this.removeHiddenBanners());
  }

  removeRestrictedBanners() {
    this.auth.getAuthUserDetails().then(() => {
      this.partnerContent = this.partnerContent.filter(
        (banner) =>
          !this.auth.myUserObservable?.restrictedPartnerBanners?.some(
            (restrictedBannerId) => restrictedBannerId === banner.partnerId
          )
      );
    });
  }

  filterBanners(partnerId: string) {
    this.partnerContent = this.partnerContent.filter((banner) => banner.partnerId !== partnerId);
    this.auth.hiddenPartnerContent.push(partnerId);
    this.auth.partnerContentChange.next();
  }

  removeHiddenBanners() {
    this.partnerContent = this.partnerContent.filter(
      (banner) => !this.auth.hiddenPartnerContent.some((hiddenBanner) => hiddenBanner === banner.partnerId)
    );
  }

  hideBannerOnce(partnerId: string) {
    this.filterBanners(partnerId);
  }

  canCloseBannerForever(partnerId) {
    this.filterBanners(partnerId);
    this.uls
      .updateUser(this.auth.myUserObservable.uid, {
        restrictedPartnerBanners: this.auth.myUserObservable.restrictedPartnerBanners
          ? [...this.auth.myUserObservable.restrictedPartnerBanners, partnerId]
          : [partnerId],
      })
      .then((user) => {
        this.auth.getAuthUserDetails();
      })
      .catch((e) => {
        console.log("Error => ", e);
      });
  }
}
