import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { InvoicePreview, Order } from "@app/interfaces/order.interface";
import { Observable } from "rxjs/internal/Observable";
import { CheckoutPdfGenerationData } from "@app/interfaces/checkout-pdf-generation-data.interface";
import { QuotationGeneration } from "@app/interfaces/quotation-generation.interface";

@Injectable({
  providedIn: "root",
})
export class OrdersService {
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.ORDERS;

  constructor(private http: HttpClient) {}

  /**
   * Requests get order route.
   * @param path path can be either orderId of the order or another path like 'my', 'my-company',...
   */
  getOrder(path?: string): Promise<Order> {
    return this.http.get<Order>(this.endpoint + "/" + (path || "")).toPromise();
  }

  /**
   * Requests get orders route.
   * @param path path can be either orderId of the order or another path like 'my', 'my-company',...
   */
  getOrders(path?: string): Observable<QueryResult<Order>> {
    return this.http.get<QueryResult<Order>>(this.endpoint + "/" + (path || ""));
  }

  /**
   * Requests get orders route intended to fetch data of type InvoicePreview
   * @param path should be a path to retrieve InvoicePreview data, e.g. "invoice-preview"
   */
  getInvoicePreview(path: string): Observable<InvoicePreview> {
    return this.http.get<InvoicePreview>(this.endpoint + "/" + (path || ""));
  }

  /**
   * sends POST Request to the orders Post Endpoint.
   * @param path Path of the request
   * @param data Data to be posted
   */
  postOrder(path?: string, data?: any) {
    return this.http.post<string>(this.endpoint + "/" + (path || ""), data).toPromise();
  }

  /**
   * Sends PUT Request to the orders PUT Endpoint.
   * @param id orderId
   * @param data Data
   */
  patchOrder(id: string, data: any) {
    return this.http.patch<string>(this.endpoint + "/" + id, data).toPromise();
  }

  /**
   * Sends DELETE Request to the orders DELETE Endpoint.
   * @param id orderId
   */
  deleteOrder(id: string) {
    return this.http.delete(this.endpoint + "/" + id).toPromise();
  }

  /**
   * Generates an order PDF and returns it as an ArrayBuffer.
   *
   * @param {CheckoutPdfGenerationData} data - Data to be posted for order PDF generation.
   *
   * @return {Promise<ArrayBuffer>} A promise that resolves with the order PDF content as an ArrayBuffer.
   */
  postResponseArrayBuffer(path: string, data: CheckoutPdfGenerationData | QuotationGeneration) {
    return this.http.post(this.endpoint + "/" + path, data, { responseType: "arraybuffer" }).toPromise();
  }
}
