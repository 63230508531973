import { EventEmitter, Injectable } from "@angular/core";
import { Order } from "@app/interfaces/order.interface";
import { MoneyService } from "@app/_services/money.service";
import { GlobalService } from "@app/_services/global.service";
import { EsoftApiService } from "@app/_services/esoft-api.service";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { CartItem } from "@app/interfaces/cart-item.interface";
import { ProductBundle } from "@app/interfaces/product-bundle.interface";
import { CartItemTypeEnum } from "@app/models/cart-item-type.enum";
import { Package } from "@app/models/package";
import { ServiceStep } from "@app/interfaces/serviceStep.interface";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { User } from "@app/interfaces/user.interface";
import * as accounting from "accounting";
import { Observable } from "rxjs/internal/Observable";
import { OrdersService } from "@app/_services/orders.service";
import { EnergyPassPackageTypeEnum } from "@app/models/energy_pass";
import { CONSTANTS } from "@app/util/constants";
import { AddVatPipe } from "@app/shared-module/add-vat.pipe";
import { CheckoutPdfGenerationData } from "@app/interfaces/checkout-pdf-generation-data.interface";
import { buildQueryString } from "@app/util/helper";
import { QuotationGeneration } from "@app/interfaces/quotation-generation.interface";

@Injectable({
  providedIn: "root",
})
export class OrdersLogicService {
  public showSubOrderDetails: EventEmitter<any> = new EventEmitter();
  public savedOrderId: string;
  public cartItems: CartItem[] = [];
  public selectedServices: any[] = [];
  public selectedProductBundlesIds: string[] = [];
  public selectedPackageIds: any[] = [];
  public selectedPackageNames: any[] = [];
  public selectedRealEstate: any;
  public isOrderSavedForLater = false;
  private allSubordersSubscription: Subscription;
  onProductModification = new EventEmitter<any>();
  onCustomModification = new EventEmitter<any>();
  onSaveServiceFinish = new EventEmitter<any>();
  onSaveServiceForLater = new EventEmitter<any>();
  selectedPackages: any = [];
  deletedSubOrderIds: string[] = [];
  previousCartItems = [];
  previousSelectedServices = [];
  previousSelectedProductBundlesIds: string[] = [];
  editingSavedOrder = false;
  showInVisLink = false;
  showOutVisLink = false;
  onCartItemRemoved = new Subject<CartItem>();
  serviceTypeTabIndexSelected = 0;
  currentPackageId = new BehaviorSubject<string>("-1");
  currentStep = new BehaviorSubject<number>(1);
  steps: { [service_key: string]: ServiceStep[] } = {}; // Central dictionary to store all order-steps in a centralized way
  targetUser: User; // Used to create orders for other users. If this value is set, when submitHandler in order-checkout is executed, the order will be created for the specified target user.
  onBundleDetected = new Subject<ProductBundle>();
  onBundleRemoved = new Subject<ProductBundle>();
  onPackageChange = new Subject<boolean>();
  onPackageRemove = new Subject<Package>();
  initiateSaveForLater = new Subject<void>();
  totalSum: string;
  undiscountedTotalSum: string;
  highlightCart = new Subject<void>();

  constructor(
    private os: OrdersService,
    private ms: MoneyService,
    private gs: GlobalService,
    protected esClient: EsoftApiService,
    public avp: AddVatPipe
  ) {
    this.handleDynamicSteps();
    this.steps[OrderTypeEnum.Documents_Procurement] = [
      {
        value: 1,
        title: "Objektdaten",
        heading: "Objektdaten angeben",
      },
      {
        value: 2,
        title: "Dokumente",
        heading: "Dokumente wählen",
      },
      {
        value: 3,
        title: "Vollmachten",
        heading: "Vollmacht hochladen",
      },
    ];
    this.steps[OrderTypeEnum.Drone_Media] = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Fotoanzahl wählen",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Format wählen, Nachbearbeitung wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];

    this.steps[OrderTypeEnum.Floor_Overview] = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Wohnungsfinder konfigurieren",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Design wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];

    this.steps[OrderTypeEnum.Floor_Plan] = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Grundriss konfigurieren",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Spezifikationen wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];

    this.steps[OrderTypeEnum.Hd_Photos] = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Fotoanzahl wählen",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Format wählen, Nachbearbeitung wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];

    this.steps[OrderTypeEnum.Price_Hubble] = [
      {
        value: 1,
        title: "Basisangaben",
        heading: "Basisangaben angeben",
      },
      {
        value: 2,
        title: "Objektdetails",
        heading: "Objektdetails angeben",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];

    this.steps[OrderTypeEnum.Video_Tour] = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Objektbegehung konfigurieren",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Nachbearbeitung wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];

    this.steps[OrderTypeEnum.V_Staging] = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Staging konfigurieren",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Einrichtungsstil wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];

    this.steps[OrderTypeEnum.Virtual_Tour] = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Größe wählen",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Nachbearbeitung wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];

    this.steps[OrderTypeEnum.Visualisation] = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Visualisierung konfigurieren",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Einrichtungsstil wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];
  }

  /**
   * Creates a new order with the given data
   * @param data data of the new order
   */
  createOrder(data: any) {
    return this.os.postOrder("", data);
  }

  /**
   * Updates an existing order specified by id with the given data
   * @param id The order id
   * @param data order data that will be updated
   */
  updateOrder(id: string, data: Partial<Order>) {
    return this.os.patchOrder(id, data);
  }

  /**
   * Deletes order by id
   * @param id The order id
   */
  deleteOrder(id: string) {
    return this.os.deleteOrder(id);
  }

  /**
   * Fetches order by its orderId from the backend
   * @param orderId The orderId of the order
   */
  getOrder(orderId: string): Promise<Order> {
    return this.os.getOrder(orderId);
  }

  /**
   * Fetches all orders from the backend. Can only accessed by the admin
   * @param from pagination from
   * @param to pagination to
   * @param sortBy sort critera
   * @param sortDirection sort direction. Can either be 'asc' or 'desc'
   * @param queryMap A map with key value pairs that will be added to the query.
   */
  getOrders(
    from?: number,
    to?: number,
    sortBy?: string,
    sortDirection?: string,
    queryMap?: Map<string, string>
  ): Observable<QueryResult<Order>> {
    const queryString = buildQueryString(from, to, sortBy, sortDirection, queryMap);
    return this.os.getOrders(queryString);
  }

  searchBillingAnalytics(
    property: string,
    monthYearString: string,
    sortBy: string,
    sortDirection: string,
    from?: number,
    to?: number
  ) {
    return this.os
      .getOrders(
        `search-billing-analytics?property=${property}&monthYear=${monthYearString}&sortBy=${sortBy}&sortDirection=${sortDirection}&from=${from}&to=${to}`
      )
      .toPromise();
  }

  getInvoicePreview() {
    return this.os.getInvoicePreview("invoice-preview").toPromise();
  }

  /**
   * Fetches the orders of the current user from the backend
   */
  getMyOrders(
    from?: number,
    to?: number,
    sortBy?: string,
    sortDirection?: string,
    queryMap?: Map<string, string>
  ): Observable<QueryResult<Order>> {
    const queryString = buildQueryString(from, to, sortBy, sortDirection, queryMap);
    return this.os.getOrders("my" + queryString);
  }

  /**
   * Fetches the orders of the current users company from the backend
   * Will return a forbidden exception when the user is not a companyManager or companyAccountant
   */
  getMyCompanyOrders(
    from?: number,
    to?: number,
    sortBy?: string,
    sortDirection?: string,
    queryMap?: Map<string, string>,
    isPHUser?: boolean
  ): Observable<QueryResult<Order>> {
    const queryString = buildQueryString(from, to, sortBy, sortDirection, queryMap);
    let urlPath = "my-company";
    if (isPHUser) {
      urlPath = "ph-orders";
    }
    return this.os.getOrders(urlPath + queryString);
  }

  /**
   * Updates the price property of the order with id orderId by adding the diff.
   * @param orderId id of the order
   * @param diff the price difference
   */
  async updateOrderPrice(orderId: string, diff: number) {
    const tempOrder = await this.getOrder(orderId);
    this.updateOrder(orderId, {
      price: this.ms.floatToString(this.ms.stringToFloat(tempOrder["price"]) + this.ms.stringToFloat(diff)),
    });
  }

  /**
   * Returns the index of the bundle in the cart
   * @param productBundle The product bundle
   */
  getCartItemProductBundleIndex(productBundle: ProductBundle) {
    return this.cartItems.findIndex(
      (item) => item.type === CartItemTypeEnum.Product_Bundle && item.data.id === productBundle.id
    );
  }

  /**
   * Adds the clicked package to a list of selected packages.
   * @param pack The clicked package.
   */
  onPackageClick(pack): void {
    const packageId = this.getCustomPackageId(pack);
    const packageName = this.getCustomPackageName(pack);
    const index = this.selectedPackages.findIndex((pck) => {
      if (!pack.productBundleData) {
        return pck.package_key === pack.package_key && !pck.productBundleData;
      } else {
        return (
          pck.package_key === pack.package_key &&
          pck.productBundleData &&
          pck.productBundleData.id === pack.productBundleData.id
        );
      }
    });

    if (index >= 0) {
      this.removeSelectedPackage(index, packageId);
      if (pack.subOrderValues && pack.subOrderValues.id) {
        const i = this.deletedSubOrderIds.indexOf(pack.subOrderValues.id);
        if (i < 0) {
          this.deletedSubOrderIds.push(pack.subOrderValues.id);
          if (pack.subOrderValues.childOrderId) {
            this.deletedSubOrderIds.push(pack.subOrderValues.childOrderId);
          }
        }
      }
      this.onPackageRemove.next(pack);
    } else {
      if (pack.formValues) {
        pack.formValues = null;
      }
      this.selectedPackages.push(pack);
      this.selectedPackageIds.push(packageId);
      this.selectedPackageNames.push(packageName);
      if (pack.subOrderValues && pack.subOrderValues.id) {
        const i = this.deletedSubOrderIds.indexOf(pack.subOrderValues.id);
        if (i >= 0) {
          this.deletedSubOrderIds.splice(i, 1);
          if (pack.subOrderValues.childOrderId) {
            const j = this.deletedSubOrderIds.indexOf(pack.subOrderValues.childOrderId);
            this.deletedSubOrderIds.splice(j, 1);
          }
        }
      }
    }
    this.onPackageChange.next(true);
    this.getTotalPrice();
  }

  /**
   * Returns a prefixed packageId in case the packageObj is part of a bundle
   * @param packageObj The package Object
   */
  getCustomPackageId(packageObj: Package) {
    const packageIdPrefix = packageObj.productBundleData ? packageObj.productBundleData.id + "|" : "";
    let packageId = packageObj.package_key;
    if (!packageObj.package_key?.includes(packageIdPrefix)) {
      packageId = packageIdPrefix + packageObj.package_key;
    }

    return packageId;
  }

  /**
   * Returns a prefixed package name in case the packageObj is part of a bundle
   * @param packageObj The package Object
   */
  getCustomPackageName(packageObj: Package) {
    const packageNamePrefix = this.getCustomPackageNamePrefix(packageObj);
    const customServicePackageName = this.getCustomServicePackageName(packageObj);
    const packageName = packageNamePrefix + customServicePackageName;

    return packageName;
  }

  /**
   * Adds the bundle name in parentesis as prefix
   * @param packageObj The Package Object
   */
  getCustomPackageNamePrefix(packageObj: Package) {
    return packageObj.productBundleData ? "(" + packageObj.productBundleData.title + ") " : "";
  }

  /**
   * Returns package name with package title and name
   * @param packageObj The Package Object
   */
  getCustomServicePackageName(packageObj: Package) {
    return packageObj.title + " - " + packageObj.name;
  }

  /**
   * Removes the package from the selectedPackages
   * @param packageIndex Index of the package
   * @param packageId packageId (=customPackageId)
   */
  removeSelectedPackage(packageIndex: number, packageId: string) {
    const idIndex = this.selectedPackageIds.indexOf(packageId);

    this.selectedPackages.splice(packageIndex, 1);
    this.selectedPackageIds.splice(idIndex, 1);
    this.selectedPackageNames.splice(idIndex, 1);
  }

  /**
   * Removes the cartItem from the selected packages
   * @param cartItem The cart item to be removed
   */
  removeCartItemSelectedPackages(cartItem: CartItem) {
    for (let i = this.selectedPackages.length - 1; i >= 0; i--) {
      const element: Package = this.selectedPackages[i];
      if (
        (cartItem.type === CartItemTypeEnum.Service &&
          element.service_key === cartItem.data.id &&
          !element.productBundleData) ||
        (cartItem.type === CartItemTypeEnum.Product_Bundle &&
          element.productBundleData &&
          element.productBundleData.id === cartItem.data.id)
      ) {
        this.removeSelectedPackage(i, this.getCustomPackageId(element));
      }
    }
  }

  /**
   * Removes the item from the cart
   * @param cartItem The cart item to be removed
   * @param index index of the item
   */
  removeItemFromCart(cartItem: CartItem, index: number): void {
    this.removeCartItemSelectedPackages(cartItem);
    this.cartItems.splice(index, 1);

    if (cartItem.type === CartItemTypeEnum.Service) {
      this.selectedServices.splice(this.selectedServices.indexOf(cartItem.data.id), 1);
    } else {
      this.selectedProductBundlesIds.splice(this.selectedProductBundlesIds.indexOf(cartItem.data.id), 1);
    }

    this.onCartItemRemoved.next(cartItem);
  }

  /**
   * empties the cart
   */
  removeAllItemsFromCart(): void {
    for (let i = this.cartItems.length - 1; i >= 0; i--) {
      const element = this.cartItems[i];
      this.removeItemFromCart(element, i);
    }
  }

  /**
   * Returns the money formatted price of the package
   * @param pack The package
   */
  getPrice(pack) {
    let val = this.gs.apls.getPositionData(pack.price, pack.productBundleData).price;
    val = this.avp.transform(val);
    return this.ms.floatToString(val);
  }

  /**
   * Returns the undiscounted money formatted price of the package
   * @param pack The package
   */
  getUndiscountedPrice(pack) {
    let val = pack.formValues.undiscounted_price;
    val = this.avp.transform(val);
    return this.ms.floatToString(val);
  }

  /**
   * Returns the money formatted unit price of the package
   * @param pack The package
   */
  getUnitPrice(pack) {
    let val = pack.formValues
      ? accounting.unformat(pack.formValues.display_price, ",") / parseInt(pack.formValues.price_qty || 1, 10)
      : this.gs.apls.getPositionData(pack.price, pack.productBundleData)?.price;
    val = this.avp.transform(val);
    return this.ms.floatToString(val);
  }

  /**
   * Returns the money formatted undiscounted unit price of the package
   * @param pack The package
   */
  getUndiscountedUnitPrice(pack) {
    let val = pack.formValues["undiscounted_price"]
      ? accounting.unformat(pack.formValues.undiscounted_price, ",") / parseInt(pack.formValues.price_qty || 1, 10)
      : this.gs.apls.getPositionData(pack.price)?.price;
    val = this.avp.transform(val);
    return this.ms.floatToString(val);
  }

  /**
   * Returns the saved orders (Observable of the saved orders)
   * @param from pagination starting pointer
   * @param to pagination end pointer
   * @param sortBy sort criterion
   * @param sortDirection sort direction
   * @param queryMap a Map<string, string> of filter values
   */
  getMySavedOrders(
    from: number,
    to: number,
    sortBy: string,
    sortDirection: string,
    queryMap: Map<string, string>
  ): Observable<QueryResult<Order>> {
    const queryString = buildQueryString(from, to, sortBy, sortDirection, queryMap);
    return this.os.getOrders("/my-saved-orders" + queryString);
  }

  private handleDynamicSteps() {
    // Bedarfsausweis
    const demandCertificate = [
      {
        value: 1,
        title: "Immobilie",
      },
      {
        value: 2,
        title: "Bauweise",
      },
      {
        value: 3,
        title: "Heizung und Lüftung",
      },
      {
        value: 4,
        title: "Upload",
      },
    ];
    // Verbrauchsausweis
    const consumptionCertificate = [
      {
        value: 1,
        title: "Immobilie",
      },
      {
        value: 2,
        title: "Verbräuche",
      },
      {
        value: 3,
        title: "Sonstige Angaben",
      },
      {
        value: 4,
        title: "Upload",
      },
    ];

    const area_calculation = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Flächenberechnung konfigurieren",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Spezifikationen wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];

    const measurement = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Aufmaß konfigurieren",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Vermessungsgrund wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Informationen mitteilen",
      },
    ];

    const retouchingBasic = [
      {
        value: 1,
        title: "Anzahl ",
        heading: "Fotos hochladen",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Nachbearbeitung wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Unterstützende Dateien hochladen",
      },
    ];

    const retouchingExtended = [
      {
        value: 1,
        title: "Anzahl ",
        heading: "Fotos hochladen",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Nachbearbeitung wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Unterstützende Dateien hochladen",
      },
    ];

    const energyAssessmentSteps = [
      {
        value: 1,
        title: "Objektaufbau und Dach",
        heading: "Objektgröße, Dach und Dachgeschoss",
      },
      {
        value: 2,
        title: "Wandaufbau und Keller",
        heading: "Wandaufbau und Dämmung, Keller",
      },
      {
        value: 3,
        title: "Fenster",
        heading: "Fenster",
      },
      {
        value: 4,
        title: "Energetische Angaben",
        heading: "Energetische Angaben",
      },
    ];

    const interiorViz = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Visualisierung konfigurieren",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Einrichtungsstil wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];

    const exteriorViz = [
      {
        value: 1,
        title: "Konfiguration",
        heading: "Visualisierung konfigurieren",
      },
      {
        value: 2,
        title: "Individualisierung",
        heading: "Stil wählen",
      },
      {
        value: 3,
        title: "Upload",
        heading: "Dateien hochladen",
      },
    ];

    this.currentPackageId.subscribe((val: string) => {
      if (val) {
        const package_key = val.split("|").pop();
        if (package_key === EnergyPassPackageTypeEnum.ConsumptionCertificate) {
          this.steps[OrderTypeEnum.Energy_Pass] = consumptionCertificate;
        }

        if (package_key === EnergyPassPackageTypeEnum.DemandCertificate) {
          this.steps[OrderTypeEnum.Energy_Pass] = demandCertificate;
        }

        if (package_key === CONSTANTS.PACKAGE_KEYS.FLOORPLAN_BASED_AREA_CALC) {
          this.steps[OrderTypeEnum.Area_Calculation] = area_calculation;
        }

        if (package_key === CONSTANTS.PACKAGE_KEYS.ON_SITE_AREA_CALC) {
          this.steps[OrderTypeEnum.Area_Calculation] = measurement;
        }

        if (package_key === CONSTANTS.PACKAGE_KEYS.RETOUCHING_BASIC) {
          this.steps[OrderTypeEnum.Retouching] = retouchingBasic;
        }

        if (package_key === CONSTANTS.PACKAGE_KEYS.RETOUCHING_EXTENDED) {
          this.steps[OrderTypeEnum.Retouching] = retouchingExtended;
        }

        if (package_key === CONSTANTS.PACKAGE_KEYS.ENERGY_ASSESSMENT) {
          this.steps[OrderTypeEnum.Energy_Pass] = energyAssessmentSteps;
        }

        if (package_key === CONSTANTS.PACKAGE_KEYS.VISUALIZATION_INNENVISUALISIERUNG) {
          this.steps[OrderTypeEnum.Visualisation] = interiorViz;
        }

        if (package_key === CONSTANTS.PACKAGE_KEYS.VISUALIZATION_AUSSENVISUALISIERUNG) {
          this.steps[OrderTypeEnum.Visualisation] = exteriorViz;
        }
      }
    });
  }

  generatePDF(data: CheckoutPdfGenerationData | QuotationGeneration) {
    return this.os.postResponseArrayBuffer("pdf", data);
  }

  getTotalPrice() {
    let orderPrice = 0;
    let undiscountedOrderPrice = 0;
    this.selectedPackages.forEach((pack) => {
      if (pack.formValues) {
        orderPrice += accounting.unformat(pack.formValues.display_price || "0,00", ",");
        undiscountedOrderPrice += accounting.unformat(
          pack.formValues.undiscounted_price || pack.formValues.display_price || "0,00",
          ","
        );
      } else if (pack.price) {
        const positionData = this.gs.apls.getPositionData(pack.price, pack.productBundleData);
        const undiscountedPositionData = this.gs.apls.getPositionData(pack.price);
        orderPrice += positionData ? positionData.price : 0;
        undiscountedOrderPrice += undiscountedPositionData ? undiscountedPositionData.price : 0;
      }
    });
    this.totalSum = accounting.formatMoney(orderPrice, "", 2, ".", ",");
    this.undiscountedTotalSum = accounting.formatMoney(undiscountedOrderPrice, "", 2, ".", ",");
  }

  downloadPDF(orderId: string) {
    return this.os.postResponseArrayBuffer("/quotation-pdf", { orderId: orderId });
  }
}
