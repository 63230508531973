import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "@environments/environment";
import { CONSTANTS } from "@app/util/constants";
import { ContactPerson, SubOrder } from "@app/interfaces/suborder.interface";
import { SuborderStatusDates } from "@app/interfaces/suborder-status-dates.interface";
import { AuthService } from "@app/auth/auth.service";
import { EsoftApiService } from "./esoft-api.service";
import { GlobalService } from "./global.service";
import { OrderTypeEnum } from "@app/models/order-type.enum";
import { FunctionsService } from "./functions.service";
import { WebsocketsService } from "./websockets.service";
import { skip, take } from "rxjs/operators";
import { Subscription } from "rxjs";
import { User } from "@app/interfaces/user.interface";
import { PaymentStatusEnum } from "@app/models/paymentStatusEnum";
import { OrdersLogicService } from "./orders-logic.service";
import { MoneyService } from "@app/_services/money.service";
import { StatusLogicService } from "@app/_services/status-logic.service";
import { SubordersService } from "@app/_services/suborders.service";
import { UsersLogicService } from "@app/_services/users-logic.service";
import { addBusinessDaysToDate, addDaysToDate, translateAppListStrings } from "@app/util/helper";
import { ConfigurationLogicService } from "./configuration-logic.service";
import { NotificationsService } from "./notifications.service";
import { SubOrderDraftStatusEnum } from "@app/models/suborder-draft-status-list";
import { RealestateLogicService } from "@app/_services/realestate-logic.service";

@Injectable({
  providedIn: "root",
})
export class SubOrdersLogicService implements OnDestroy {
  private readonly logId = "SubOrdersLogicService :: ";
  private readonly endpoint = environment.apiUrl + CONSTANTS.BACKEND_ENDPOINTS.SUBORDERS;
  currentSuborder: SubOrder;
  currentSuborderAutoCloseDate: Date;
  private updateSub: Subscription;
  paymentStatusEnum = PaymentStatusEnum;

  constructor(
    private wss: WebsocketsService,
    private auth: AuthService,
    private esClient: EsoftApiService,
    private uls: UsersLogicService,
    private gs: GlobalService,
    private fs: FunctionsService,
    private ols: OrdersLogicService,
    private sls: StatusLogicService,
    private ms: MoneyService,
    private sos: SubordersService,
    private ns: NotificationsService,
    private conf: ConfigurationLogicService,
    private rls: RealestateLogicService
  ) {
    this.wss.currentSuborder.subscribe((suborder) => {
      if (suborder) {
        this.currentSuborder = suborder;
        this.setCurrentSuborderAutoCloseDate();
      }
    });
  }

  createSubOrder(data: any) {
    return this.sos.postSuborder("", data);
  }

  updateSubOrder(id: string, data: Partial<SubOrder>) {
    return this.sos.patchSuborder(id, data);
  }

  deleteSuborder(id: string) {
    return this.sos.deleteSuborder(id);
  }

  getSuborder(id: string) {
    return this.sos.getSuborder(id);
  }

  createPriceHubbleOrder(suborderId: string) {
    return this.sos.postSuborder("/create-price-hubble-order", { suborderId: suborderId });
  }

  getSuborders(revenueStartDate?, revenueEndDate?) {
    return this.sos.getSuborders(revenueStartDate, revenueEndDate);
  }
  getRelatedSuborders(id: SubOrder["id"]) {
    return this.sos.getRelatedSuborders(id);
  }

  /**
   * Changes the field value of the suborder with subid
   * @param subid suborder Id
   * @param fieldToChange The field to change
   * @param value The new value
   * @param additionalData Additional data to be stored in the suborder.
   */
  changeSuborder(subid: string, fieldToChange: string, value: any, additionalData = {}) {
    let data = {};
    data[fieldToChange] = value;

    if (additionalData.constructor === Object && Object.keys(additionalData).length !== 0) {
      data = {
        ...data,
        ...additionalData,
      };
    }

    return this.updateSubOrder(subid, data);
  }

  sendOrderCompletionEmail(subOrderId: string, outputFolder?: string, comments?: string, withPlayer?: boolean): void {
    const folderName = outputFolder || "outputPhotos";
    const subOrder = this.wss.fetchSuborder(subOrderId);
    this.uls.getUserDetails(subOrder.createdBy).subscribe(async (customerData: any) => {
      subOrder.id = subOrderId;
      const realEstate = await this.rls.getRealestate(subOrder.realestateId).toPromise();
      const requestData = {
        subOrderDetails: [],
        attachments: [],
        orderId: subOrder["orderId"],
        customerEmail: subOrder.forwardEmails?.length > 0 ? subOrder.forwardEmails.join(",") : customerData.email,
        comments: comments || "",
        isWithPlayer: withPlayer || false,
        isFALCUser: environment.FALC_companyId === customerData.company?.id,
      };
      subOrder.attachmentsLink =
        environment.apiUrl + "files/downloadzip?oid=" + subOrderId + "&dir=" + folderName + "&uid=" + customerData.uid;
      requestData.subOrderDetails.push({
        fields: this.gs.getEmailSubOrderKeyValuePairs(subOrder, realEstate),
        produkt: subOrder["packageName"] || translateAppListStrings("order_type_list", subOrder.orderType),
        orderType: subOrder.orderType,
        referenceFile: false,
        attachmentsLink:
          subOrder.selectedPackage === "Video" ||
          subOrder.selectedPackage === "Drohnenaufnahmen - Video" ||
          subOrder.orderType === OrderTypeEnum.Virtual_Tour
            ? ""
            : subOrder.attachmentsLink || "",
        isVideo: !!subOrder.outputVideoUrl,
        videoLink: subOrder.outputVideoUrl || "",
        videoLinkMsg:
          subOrder.orderType === OrderTypeEnum.V_Staging
            ? "Link zur Ihrer fertiggestellten virtuellen Tour"
            : "Link zur Ihrem fertiggestellten Video",
        subOrder: subOrder,
      });
      this.fs.callFunction("sendOrderCompletionEmail", requestData).subscribe();
    });
  }

  /**
   * Adds documentation info to a suborder.
   * @param suborderId The suborder id.
   * @param updatedDocumentation The updated documentation.
   */
  async addToDocumentation(suborderId: string, updatedDocumentation: any[]) {
    console.log(suborderId, updatedDocumentation);
    await this.updateSubOrder(suborderId, {
      documentation: updatedDocumentation,
    });
  }

  /**
   * Adds statusDates to the given suborder
   * @param suborder SubOrder Object
   * @param newStatusDates The new Status Dates
   */
  async createStatusDates(suborder: SubOrder, newStatusDates: SuborderStatusDates) {
    await this.updateSubOrder(suborder.id, {
      statusDates: newStatusDates,
    });
  }

  createContactPerson(userDetails: User) {
    return <ContactPerson>{
      id: userDetails.uid,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email ? userDetails.email : "",
      phone: userDetails.phone ? userDetails.phone : "",
      profilePhoto: userDetails.profilePhoto ? userDetails.profilePhoto : "",
    };
  }

  getLastStatusDate(statusDates: any, lastStatusDate: Date) {
    for (const i in statusDates) {
      if (statusDates[i] instanceof Date && statusDates[i] > lastStatusDate) {
        lastStatusDate = statusDates[i];
      } else if (Array.isArray(statusDates[i])) {
        for (const item of statusDates[i]) {
          if (item instanceof Date) {
            lastStatusDate = this.getLastStatusDate([item], lastStatusDate);
          } else {
            lastStatusDate = this.getLastStatusDate(item, lastStatusDate);
          }
        }
      }
    }

    return lastStatusDate;
  }

  /**
   * Adds a default payments value to all suborders, when none exists.
   */
  async addPaymentsValue() {
    this.wss.subscribeToSubordersList();

    this.wss.subOrderList.pipe(skip(1), take(1)).subscribe(async (suborders) => {
      suborders.forEach((element: SubOrder) => {
        if (!element.payments) {
          element["payments"] = this.paymentStatusEnum.None;
          this.updateSubOrder(element.id, element);
        }
      });

      this.wss.unsubscribeFromSuborders();
    });
  }

  /**
   * Marks all suborders payments as paid.
   */
  markAsPaid() {
    this.wss.subscribeToSubordersList();

    this.wss.subOrderList.pipe(skip(1), take(1)).subscribe(async (suborders) => {
      suborders.forEach((element: SubOrder) => {
        element["payments"] = "paid";
        this.updateSubOrder(element.id, element);
      });

      this.wss.unsubscribeFromSuborders();
    });
  }

  // Buys a feedback for the given suborder, adds the purchase to the invoice details updates suborder and order price sums and returns a Promise if purchase was succesfully.
  async buyFeedback(subOrder: SubOrder, claim = false): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await this.changeSuborder(subOrder.id, "feedbacks_bought", subOrder.feedbacks_bought + 1 || 1);
      await this.sls.updateOrderStatusSync(subOrder.orderId);

      let noOfExtraFeedbacks = 0;
      let indexOfFeedbackloops = -1; // Index of feedback-loops position in invoice_details
      if (!subOrder["invoice_details"]) {
        subOrder["invoice_details"] = [];
      }
      for (let i = 0; i < subOrder["invoice_details"].length; i++) {
        if (subOrder["invoice_details"][i]["description"].indexOf("zusätzliches Feedback") >= 0) {
          noOfExtraFeedbacks = subOrder["invoice_details"][i]["quantity"];
          indexOfFeedbackloops = i;
          break;
        }
      }
      noOfExtraFeedbacks += 1;

      if (claim) {
        const chatList = subOrder.adminChatHistory || [];
        chatList.push({
          dateTime: new Date(),
          message: `Kunde hat in Feedbackschleife ${subOrder.feedbackNumber} eine Reklamation beantragt.`,
          uid: this.auth.myUserObservable.uid,
          userName: this.auth.myUserObservable.firstName + " " + this.auth.myUserObservable.lastName,
        });

        await this.updateSubOrder(subOrder.id, { adminChatHistory: chatList });
      }

      if (!claim) {
        if (indexOfFeedbackloops === -1) {
          if (subOrder.extra_feedback_accounting_position && subOrder.extra_feedback_name) {
            subOrder["invoice_details"].push({
              quantity: noOfExtraFeedbacks,
              description: subOrder.extra_feedback_name,
              total: subOrder.extra_feedback_price,
              "accounting-position": subOrder.extra_feedback_accounting_position,
            });
          } else {
            subOrder["invoice_details"].push({
              quantity: noOfExtraFeedbacks,
              description: "Korrekturschleife",
              total: noOfExtraFeedbacks * subOrder.extra_feedback_price,
            });
          }
        } else {
          // feedbackloops in invoice --> Just update the values.
          if (subOrder.extra_feedback_accounting_position && subOrder.extra_feedback_name) {
            subOrder["invoice_details"][indexOfFeedbackloops] = {
              quantity: noOfExtraFeedbacks,
              description: subOrder.extra_feedback_name,
              total: subOrder.extra_feedback_price,
              "accounting-position": subOrder.extra_feedback_accounting_position,
            };
          } else {
            subOrder["invoice_details"][indexOfFeedbackloops] = {
              quantity: noOfExtraFeedbacks,
              description: "Korrekturschleife",
              total: noOfExtraFeedbacks * subOrder.extra_feedback_price,
            };
          }
        }
        this.updateInvoicePositions(subOrder, false);
        await this.updateSubOrder(subOrder.id, { invoice_details: subOrder["invoice_details"] });
      }
      resolve(true);
    });
  }

  /**
   * Updates invoice positions in the given suborder
   * @param suborder the suborder
   * @param prepayment flag indicating if a prepayment was done already.
   */
  updateInvoicePositions(suborder: SubOrder, prepayment: boolean) {
    let sum = 0.0;
    const sumBefore = this.ms.stringToFloat(suborder["total_price"]);
    let billed_sum = 0;
    // check for old version of invoice_details

    suborder["invoice_details"].forEach((item) => {
      item.discount
        ? (sum += item.quantity * this.ms.stringToFloat(item.total) * (1 - item.discount))
        : (sum += item.quantity * this.ms.stringToFloat(item.total));
      if (item["accounting-position"] === "billed") {
        billed_sum += item.total;
      }
    });
    this.updateSubOrder(suborder.id, {
      total_price: this.ms.floatToString(sum),
      billed_sum: this.ms.floatToString(billed_sum),
      invoice_details: suborder["invoice_details"],
    });

    if (prepayment) {
      this.ols.updateOrderPrice(
        suborder["orderId"],
        this.ms.stringToFloat(sum) - this.ms.stringToFloat(sumBefore) - billed_sum
      );
    } else {
      this.ols.updateOrderPrice(suborder["orderId"], this.ms.stringToFloat(sum) - this.ms.stringToFloat(sumBefore));
    }
  }

  /**
   * Sets a suborder including its accounting positions to paid.
   */
  async setSuborderPaid(suborder: SubOrder) {
    const myUser = this.auth.myUserObservable;

    suborder.invoice_details?.forEach((invoiceDetail) => {
      const position_total = invoiceDetail.total * invoiceDetail.quantity * (1 - (invoiceDetail.discount || 0));
      invoiceDetail.paid_amount = position_total;
      invoiceDetail.billed_amount = position_total;
    });

    const newDocumentationEntry = {
      date: new Date(),
      role: myUser.role,
      text: myUser.email + " hat die Bestellung manuell als bezahlt markiert.",
    };

    if (suborder.documentation?.length) {
      suborder.documentation.push(newDocumentationEntry);
    } else {
      suborder.documentation = [newDocumentationEntry];
    }

    let updateData = {
      invoice_details: suborder.invoice_details,
      payments: PaymentStatusEnum.Paid,
      documentation: suborder.documentation,
    };

    await this.sos.patchSuborder(suborder.id, updateData);
  }

  /**
   * Sets the whole order of the given suborder as paid. Therefore, all suborders will be set to paid and the
   * payment status of the order is set to paid
   * @param suborderId string
   */
  async setOrderPaid(suborderId: string) {
    const relatedSuborders = await this.sos.getRelatedSuborders(suborderId);
    const promises = [];
    relatedSuborders.forEach((so) => promises.push(this.setSuborderPaid(so)));

    await Promise.all(promises);

    await this.ols.updateOrder(relatedSuborders[0].orderId, { payments: PaymentStatusEnum.Paid });
  }

  uploadAllowedChange(uploadAllowed) {
    let data = <any>{
      uploadAllowed: uploadAllowed,
      customerActionRequired: uploadAllowed,
    };

    if (uploadAllowed) {
      const now = new Date();
      if (!this.currentSuborder.statusDates.customerUploadsAllowed) {
        this.currentSuborder.statusDates.customerUploadsAllowed = [now];
      } else {
        this.currentSuborder.statusDates.customerUploadsAllowed.push(now);
      }

      data = {
        ...data,
        statusDates: this.currentSuborder.statusDates,
      };
    }
    return this.updateSubOrder(this.currentSuborder.id, data);
  }

  async extendAutoClose() {
    const autoCloseDaysIncrease = this.conf.autoPilotConfig.autoCloseDaysIncrease;
    console.log(this.logId + "Extending auto close days by:", autoCloseDaysIncrease);

    this.currentSuborder.autoClosingDays += autoCloseDaysIncrease;
    this.setCurrentSuborderAutoCloseDate();
    try {
      await this.updateSubOrder(this.currentSuborder.id, {
        autoClosingDays: this.currentSuborder.autoClosingDays,
      });
      this.ns.showNotification("Auto-Closing-Tag erfolgreich verlängert.", "success");
    } catch (error) {
      this.ns.showNotification(
        "Die Aktion konnte nicht abgeschlossen werden. Bitte versuchen Sie es erneut.",
        "danger"
      );
    }
  }

  setCurrentSuborderAutoCloseDate() {
    if (this.currentSuborder.autoClosingDays === undefined) {
      console.log(this.logId + "No auto closing days found on suborder! Ignoring auto close date set!");
      return;
    }

    const lastStatusDate = this.getLastStatusDate(this.currentSuborder.statusDates, this.currentSuborder.createdOn);
    this.currentSuborderAutoCloseDate = addDaysToDate(lastStatusDate, this.currentSuborder.autoClosingDays);
  }

  // * Increases FeedbackNumber, updates StatusDates and stores result in drafts */
  async finalizeSuborderFeedback(result: SubOrderDraftStatusEnum, suborder: SubOrder) {
    const dateNow = new Date();
    suborder.statusDates.feedbacks[suborder.feedbackNumber - 1].feedback = dateNow;

    if (result === SubOrderDraftStatusEnum.Accepted || result === SubOrderDraftStatusEnum.Finally_Accepted) {
      suborder.statusDates.feedbacks[suborder.feedbackNumber - 1][result] = dateNow;
    }

    // adding 24h to the processing time in order to get the estimated delivery date
    const estimatedDelivery = addBusinessDaysToDate(dateNow, 1);
    suborder.statusDates.feedbacks.push(<any>{
      estimatedDelivery: suborder.hasAutoPilot ? estimatedDelivery : "",
      delivery: "",
      feedback: "",
    });
    await this.sls.updateStatusDates(suborder, suborder.statusDates, true, false, false, false);
    const newLoop = suborder.feedbackNumber + 1;
    await this.changeSuborder(suborder.id, "feedbackNumber", newLoop);

    if (!suborder.drafts) {
      suborder.drafts = [];
    }
    if (suborder.drafts && suborder.numberOfDrafts && suborder.drafts.length < suborder.numberOfDrafts) {
      suborder.drafts.push(result);
    }

    await this.changeSuborder(suborder.id, "drafts", suborder.drafts);
  }

  getSubordersOfOrder(orderId: string): Promise<SubOrder[]> {
    return this.sos.getSubordersOfOrder(orderId);
  }

  ngOnDestroy(): void {
    if (this.updateSub) {
      this.updateSub.unsubscribe();
    }
  }
}
